
import "./App.css";
import "./Component.css";
import "./Splitter.css";
import Component from './Component';

function App() {
  return (
    <Component/>
  );
}

export default App;
